<template>
  <div class="app-container">
    <div v-if="loading" v-loading="true" style="height: calc(100% - 35px);"></div>
    <baseTable  v-else class="flex-one-page" :list="list" :option="option" :isInit="false" initOnTabChange>
      <template #menuLeft>
        <el-button v-if="childPermission.add" type="primary" size="small" @click="linkTo('add')">新增字段</el-button>
      </template>
      <template #rule="{ row }">
        <rule :data="row" :curTabItem="curTabItem" />
      </template>
      <template #menu="{ row }">
        <color-text-btn v-if="childPermission.edit" @click="linkTo('edit', row)">编辑</color-text-btn>
        <loadingBtn v-if="childPermission.del" type="text" @click="onDelete(row)">删除</loadingBtn>
      </template>
    </baseTable>
  </div>
</template>

<script>
import getBaseTableData from '@/components/base/baseTable/mixins/getBaseTableDataMixin'
import baseTable from '@/components/base/baseTable/mixins/baseTable'
import baseTableMixin from '@/components/base/baseTable/mixins/baseTableMixin'
import rule from './module/rule'
import fieldApi from '@/api/productTemplate/fieldSettings'
import { getPlatformList } from '@/api/shop/manageApi'
import { option, amaOption } from './module/option'
import { TYPE_ALIEXPRESS_NUM, TYPE_AMAZON_NUM, TYPE_OTHER_NUM } from '@/utils/constant/fieldConst'
import { checkPermission } from '@/utils'
export default {
  name: 'fieldSettings',
  mixins: [
    baseTable,
    baseTableMixin(),
    getBaseTableData({
      dataAttrs: {
        curTabItem: {}
      }
    })
  ],
  components: { rule },
  data() {
    return {
      option,
      amaOption,
      loading: false,
      list: [],
      platformList: [],
    }
  },

  computed: {
    childPermission({ curTabItem }) {
      return $GET(curTabItem, 'childPermission', {})
    }
  },
  async created() {
    this.loading = true
    const res = await awaitResolve(getPlatformList({}))
    if (!res) return (this.loading = false)
 
    const arr = res?.detail.filter(({code})=>
      [TYPE_ALIEXPRESS_NUM, TYPE_AMAZON_NUM].includes(Number(code))
    )
    const mappingList = {
      options:{
        [TYPE_ALIEXPRESS_NUM]: option,
        [TYPE_AMAZON_NUM]: amaOption
      },
      api:{
        [TYPE_ALIEXPRESS_NUM]: fieldApi.getAliColumnList,
        [TYPE_AMAZON_NUM]: fieldApi.list
      }
    }
    const permissionList ={
      [TYPE_ALIEXPRESS_NUM]:{
        prefix:'smt',
        childPermission:{
          add: checkPermission('externaladmin:productTemplate:fieldSettings:smt:add'),
          edit: checkPermission('externaladmin:productTemplate:fieldSettings:smt:edit'),
          del: checkPermission('externaladmin:productTemplate:fieldSettings:smt:del')
        }
      },
      [TYPE_AMAZON_NUM]:{
        prefix:'ymx',
        childPermission:{
          add: checkPermission('externaladmin:productTemplate:fieldSettings:ymx:add'),
          edit: checkPermission('externaladmin:productTemplate:fieldSettings:ymx:edit'),
          del: checkPermission('externaladmin:productTemplate:fieldSettings:ymx:del')
        }
      }
    }

    arr.forEach(({ name, code, id }) => {
      this.list.push({
        label: name,
        value: code,
        prop: name,
        permission: checkPermission(`externaladmin:productTemplate:fieldSettings:${permissionList[code]['prefix']}`),
        childPermission: permissionList[code]['childPermission'],
        getList: mappingList.api[code],
        resetMergeData: {
          shopTypeId: id
        },
        option:mappingList.options[code]
      })
    })
    this.loading = false

  },

  methods: {
    linkTo(type, data = {}) {
      this.$router.push({
        path: '/productTemplate/addOrEditField',
        query: {
          id: type == 'edit' ? data.id : undefined,
          platType: this.curTabItem?.resetMergeData?.shopTypeId,
          code:this.curTabItem?.value,
        }
      })
    },

    async onDelete(data) {
      try {
        const res = await this.$confirm('确定删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        if (res != 'confirm') return
        const code = this.curTabItem?.value
        const fun = code == [TYPE_ALIEXPRESS_NUM] ? fieldApi.deleteAliColumnMap : fieldApi.del
        const result = await awaitResolve(
          fun({
            idList: [data.id]
          })
        )
        if (!result) return
        this.init()
        this.$message.success('删除成功')
      } catch (error) {}
    }
  }
}
</script>

<style lang="scss" scoped></style>
